import React, { useEffect } from "react"
import FlowtyLink from "../FlowtyLink"
import { flowtyDiscordLink } from "flowty-common"
import { TransactionFollowBox } from "../../../../TransactionFollowBox"
import { ReactComponent as RobotOops } from "../../../../assets/robot-oops.svg"
import { parseError } from "flowty-sdk/lib/utils/ContractErrors"
import Swal from "sweetalert2"

interface TransactionFailureProps {
	transactionID?: string | null
	isMainnet: boolean
	error?: string
}

export const TransactionFailure: React.FunctionComponent<
	TransactionFailureProps
> = ({ transactionID, isMainnet, error }) => {
	const { title, description, swalAlertOptions } = parseError(error ?? "")

	useEffect(() => {
		if (!swalAlertOptions) return
		const handleSwal = async () => {
			await Swal.fire({
				background: "#031021",
				color: "#FFFFFF",
				confirmButtonColor: "#FF6969",
				icon: "error",
				showConfirmButton: true,
				text: description,
				timer: 5000,
			})
		}

		handleSwal()
	}, [swalAlertOptions])

	return (
		<div className='w-full md:w-[400px] flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<div className='flex flex-col justify-center items-center gap-[8px]'>
				<RobotOops className='w-[240px] h-[240px]' />
				<p className='text-white text-[16px] font-semibold text-center'>
					{title}
				</p>
			</div>
			<div className='flex flex-col h-[100px] text-[#F8F9FA] text-center font-normal text-[14px]'>
				<div className='pb-[10px] '>{description}</div>
				<div>
					Need help? Please open a support ticket in{" "}
					<FlowtyLink text='Discord' href={flowtyDiscordLink} />
				</div>
				<TransactionFollowBox
					transactionID={transactionID}
					isMainnet={isMainnet}
				/>
			</div>
		</div>
	)
}
