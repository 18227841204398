import { OpensearchFlowNFT, getMessageFromError } from "flowty-common"
import { inject, observer } from "mobx-react"
import { useState } from "react"
import { useHybridCustodyContext } from "../../../../../../../contexts/HybridCustodyContext"
import { useFlowNFT } from "../../../../../../../hooks/data/nfts/useFlowNFT"
import { AuthStoreProp } from "../../../../../../../stores/AuthStore"
import { actions as Mixpanel } from "../../../../../../../util/Mixpanel"
import { getBestCollectionPrivatePath } from "../../../../../../../util/getBestPrivatePath"
import { flowscanBaseURL } from "../../../../../../../util/settings"
import { TransferForm } from "./TransferForm"
import { TransferTransactionStatus } from "./TransferTransactionStatus"
import { flowty } from "../../../../../../../config/config"
import { selectedWalletFromLoggedUser } from "../../../../../../../components/Modals/ListedActions/MultiActionModal/Reducer/checkAccount"
import { CustomErrorMessages } from "flowty-sdk"

interface TransferPreviewProps extends AuthStoreProp {
	asset: OpensearchFlowNFT
}

const TransferPreviewComponent: React.FC<TransferPreviewProps> = ({
	asset,
	authStore,
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(false)
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [sealed, setIsSealed] = useState(false)
	const [statusUncertain, setIsStatusUncertain] = useState(false)
	const [transferAddress, setTransferAddress] = useState("")

	const { hybridCustodyNFTStatus } = useHybridCustodyContext()

	const loggedUser = authStore?.loggedUser

	const { flowNFT } = useFlowNFT({
		nftId: `${asset?.id}`,
		nftType: asset.type,
	})

	const mainAccount = selectedWalletFromLoggedUser(loggedUser!)

	const childAccounts = Object.values(loggedUser?.accountSummaries ?? {})

	const accounts = [mainAccount, ...childAccounts]

	const collectionIdentifier = `${flowNFT?.contractAddress}.${flowNFT?.contractName}`
	const collectionProviderPath =
		flowNFT?.nftView?.collectionData?.providerPath?.identifier

	const nftProviderPathIdentifier =
		loggedUser?.walletAddress === asset?.owner
			? ""
			: getBestCollectionPrivatePath(
					hybridCustodyNFTStatus?.[flowNFT?.owner || ""]?.[
						collectionIdentifier
					] || [],
					flowNFT?.contractAddress,
					flowNFT?.contractName,
					collectionProviderPath
			  )

	const onSubmit = async (address: string) => {
		setTransferAddress(address)
		setIsLoading(true)
		setError(false)
		setTransactionID(null)
		try {
			await flowty
				.transferNft({
					nftData: {
						contractAddress: flowNFT?.contractAddress || "",
						contractName: flowNFT?.contractName || "",
						id: flowNFT?.id || "",
						nftOwner: flowNFT?.owner || "",
						type: flowNFT?.type || "",
					},
					nftProviderPathIdentifier,
					transferRecipient: address,
					txAvailableCallback: (id: string) => setTransactionID(id),
				})
				.then(response => {
					if (
						(response?.status !== 4 &&
							response?.toString()?.includes("Error")) ||
						response?.toString()?.includes("Declined")
					) {
						throw new Error(response)
					}
				})

			setIsSealed(true)
			setIsLoading(false)
			Mixpanel.track(`Successful transfer listing transaction`, {
				listingData: flowNFT,
			})
		} catch (err) {
			setError(true)
			const msg = getMessageFromError(err)
			if (msg === CustomErrorMessages.UnableToFollowTransactionResult) {
				setIsStatusUncertain(true)
			}
			setIsLoading(false)
			Mixpanel.track(`Error transfer listing transaction`, {
				err: err,
				listingData: flowNFT,
			})
		}
	}

	if (!flowNFT || !loggedUser) {
		return null
	}

	return (
		<div className='min-h-[280px] mt-[1.5rem] rounded-[0.5rem] flex flex-col justify-center p-[1.5rem] bg-[#606e7d33] w-auto md:w-[25rem] gap-y-[1.5rem]'>
			{isLoading || error || sealed ? (
				<TransferTransactionStatus
					isLoading={isLoading}
					error={error}
					transactionID={transactionID}
					flowscanBaseURL={flowscanBaseURL}
					statusUncertain={statusUncertain}
					sealed={sealed}
					onSubmit={() => onSubmit(transferAddress)}
				/>
			) : (
				<TransferForm
					nft={flowNFT}
					loggedUser={loggedUser}
					accounts={accounts}
					onSubmit={onSubmit}
				/>
			)}
		</div>
	)
}

export const TransferPreview = inject("authStore")(
	observer(TransferPreviewComponent)
)
