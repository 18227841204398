import axios from "axios"
import {
	FCLTransactionResult,
	FlowtyException,
	ONE_DAY_IN_SECONDS,
	StorefrontV2ListingAvailable,
	getMessageFromError,
} from "flowty-common"
import { getCatalogIdentifiersScript } from "../scripts/getCatalogIdentifiers"
import { Config } from "../types"
import { CustomErrorMessages } from "./ContractErrors"

const fcl = require("@onflow/fcl")
const t = require("@onflow/types")

export const IS_MAINNET = process.env.REACT_APP_ACCESS_API?.includes("mainnet")

export const Err = (...data: any[]): void => {
	console.error(data)
}

export const termNumberToSeconds = (termNumber: number): number =>
	termNumber * ONE_DAY_IN_SECONDS

export const expirationDaysToSeconds = (expiryInDays: number): number =>
	Math.round(expiryInDays * ONE_DAY_IN_SECONDS)

const getScriptContent = async (
	transactionCdcScript: string
): Promise<string> => {
	if (!transactionCdcScript.endsWith(".cdc")) {
		return transactionCdcScript as string
	}

	const transactionScriptRaw: any = await axios.get(transactionCdcScript)
	return transactionScriptRaw.text()
}

const loadScriptContent = async (
	transactionCdcScript: string | ScriptWithParams
): Promise<string> => {
	let scriptContent

	if (typeof transactionCdcScript === "string") {
		scriptContent = await getScriptContent(transactionCdcScript)
	} else {
		const scriptParams = transactionCdcScript as ScriptWithParams
		let scriptContentWithParams = await getScriptContent(scriptParams.script)
		scriptParams.params.forEach(({ placeholder, param }: ScriptParam) => {
			scriptContentWithParams = scriptContentWithParams.replaceAll(
				placeholder,
				param
			)
		})

		scriptContent = scriptContentWithParams
	}
	return scriptContent
}

export interface ScriptParam {
	placeholder: string
	param: string
}

export interface ScriptWithParams {
	script: string
	params: ScriptParam[]
}

export const executeScript = async <T>(
	cdcScript: string,
	args: any[],
	label: string = "unknown",
	retries: number = 1,
	backoff: number = 1000
): Promise<T> => {
	try {
		const response = await fcl.send([fcl.script`${cdcScript}`, fcl.args(args)])
		return fcl.decode(response) as T
	} catch (e) {
		const error = new Error(getMessageFromError(e))
		if (retries > 1) {
			await delay(backoff)
			return executeScript<T>(cdcScript, args, label, retries - 1)
		}

		throw new FlowtyException(error, {}, `executeScript::${label}`)
	}
}

const getIdentifierFromCatalog = async (
	nftType: string,
	config: Config
): Promise<{ [key: string]: boolean } | null> => {
	return executeScript<{ [key: string]: boolean } | null>(
		getCatalogIdentifiersScript(config),
		[fcl.arg(nftType, t.String)],
		"getCatalogIdentifiersScript"
	)
}

export const getCatalogEntryForType = async (
	nftType: string,
	config: Config
): Promise<string | null> => {
	const catalogIdentifiers = await getIdentifierFromCatalog(nftType, config)
	if (!catalogIdentifiers) {
		// this means we don't know how to list the asset for sale since it is not part of the NFTCatalog!
		return null
	}

	let firstEntry = ""
	const keys = Object.keys(catalogIdentifiers)
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i]
		if (catalogIdentifiers[key]) {
			firstEntry = key
			break
		}
	}

	return firstEntry
}

export const delay = (ms: number): Promise<void> =>
	new Promise(resolve => {
		setTimeout(resolve, ms)
	})

export const waitForSeal = async (
	transactionID: string,
	retries: number = 5,
	backoff: number = 1000
): Promise<FCLTransactionResult> => {
	try {
		return (await fcl.tx(transactionID).onceSealed()) as FCLTransactionResult
	} catch (e) {
		const msg = getMessageFromError(e)
		if (
			msg.includes("panic") ||
			msg.includes("assertion failed") ||
			msg.includes("Execution failed")
		) {
			throw new Error(msg)
		}

		if (retries === 0) {
			throw new Error(CustomErrorMessages.UnableToFollowTransactionResult)
		}
		await delay(backoff)
		return waitForSeal(transactionID, retries - 1)
	}
}

export const sendMutation = async (
	transactionCdcScript: string | ScriptWithParams,
	args: Array<any>, // we have to use any here because fcl is not typed :(
	txAvailableCallback?: (transactionId: string) => void
): Promise<FCLTransactionResult> => {
	const cadence = await loadScriptContent(transactionCdcScript)

	console.log("sendMutation", { args, transactionCdcScript })

	const transactionId = await fcl.mutate({
		args: (arg: any, _: any) => args, // we have to use any here because fcl is not typed :(
		authorizations: [fcl.authz],
		cadence,
		limit: 9999,
		payer: fcl.authz,
		proposer: fcl.authz,
	})

	if (txAvailableCallback) {
		txAvailableCallback(transactionId)
	}
	const transaction = await waitForSeal(transactionId)
	return transaction
}

interface ValidateListingRequest {
	listingType: string
	listingID: string
}

interface ValidateListingResponse extends ValidateListingRequest {
	valid: boolean
}

export const validateListing = async (
	listing: StorefrontV2ListingAvailable,
	apiURL: string
): Promise<boolean> => {
	const request: ValidateListingRequest = {
		listingID: listing.listingResourceID,
		listingType: "storefront",
	}

	const res = await axios.post<ValidateListingResponse>(
		`${apiURL}/validate/listing`,
		request,
		{
			validateStatus: _ => true, // do not throw on non-200
		}
	)

	if (res.status !== 200) {
		// we will only mark something as invalid if it is explicitly flagged as such
		return true
	}

	return res.data.valid === true
}
