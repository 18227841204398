import { Timestamp } from "firebase/firestore"
import {
	Card,
	FlowNFTContract,
	nftTypeToContractID,
	RentalListingAvailableData,
} from "flowty-common"
import { LoanRentalFilteredData } from "flowty-sdk"
import React, { useCallback, useEffect, useState } from "react"
import StepWizard from "react-step-wizard"
import { flowty } from "../../../config/config"
import { firestore } from "../../../firebase"
import { getListingRentedDataByListingId } from "../../../services/firestore/MarketplaceService"
import { Log } from "../../../util/Log"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import ReturnRentalModal from "../../Modals/ReturnRentalModal/ReturnRentalModal"
import { TxSuccessful } from "../../Modals/shared/TxSuccessful"
import FailedTransaction from "./Shared/FailedTransaction"
import TransactionHandling from "./Shared/Transaction"

interface ReturnRentalProps {
	rentalListing: RentalListingAvailableData
	nftProviderPathIdentifier: string
	nftProviderAddress: string
}

const ReturnRental: React.FC<ReturnRentalProps> = ({
	rentalListing,
	nftProviderPathIdentifier,
	nftProviderAddress,
}) => {
	const [show, setShow] = useState<boolean>(false)
	const [error, setError] = useState<string | null>(null)
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [sealed, setSealed] = useState<boolean>(false)
	const [disableButton, setDisableButton] = useState(false)

	useEffect(() => {
		setDisableButton(false)
	}, [rentalListing])

	const setAllFormStatesNull = (): void => {
		setTransactionID(null)
		setError(null)
	}

	const toggleModal = (): void => {
		setShow(!show)
		setAllFormStatesNull()
		if (show) {
			Mixpanel.track("Return Rental Modal Launched", { rentalListing })
		} else {
			Mixpanel.track("Return Rental Modal Closed", { rentalListing })
		}
	}

	const execute = async (): Promise<void> => {
		try {
			setDisableButton(true)
			const rental = await getListingRentedDataByListingId(
				rentalListing.listingResourceID
			)
			if (!rental) {
				setError("rental does not have an associated listing")
				Mixpanel.track("Return Rental Submit Error", { error, rentalListing })
				return
			}

			const token = flowty.tokens.getTokenInfo(
				rental.listingAvailable.paymentTokenName
			)

			const contractID = nftTypeToContractID(rental.nftType)
			const nftContract = (
				await firestore.collection("flowNFTContract").doc(contractID).get()
			).data() as FlowNFTContract

			if (!nftContract) {
				throw new Error("Contract not found")
			}

			const settleDate = rental?.settleDeadline as unknown as Timestamp
			const rentalActionsObject: LoanRentalFilteredData = {
				enabledAutoRepayment: rental?.enabledAutomaticReturn,
				listingResourceID: String(rental?.listingResourceID),
				nftContractStoragePath: String(
					rental?.listingAvailable?.detail?.nft?.contract?.contractMetadata
						?.storagePath
				),
				nftData: {
					...(rental?.listingAvailable?.detail?.card as Card),
				},
				nftID: rental?.listingAvailable?.nftID,
				nftType: rental?.listingAvailable?.nftType,
				paymentTokenName: rental?.listingAvailable?.paymentTokenName,
				rentalFee: rental?.amount,
				rentalRefundableDeposit: rental?.deposit,
				rentalResourceID: String(rental?.rentalResourceID),
				settleDeadline: +settleDate.seconds,
				term: rental?.listingAvailable?.term,
				type: "rental",
			}

			await flowty.returnRental(
				rentalActionsObject,
				token,
				setTransactionID,
				nftProviderAddress,
				nftProviderPathIdentifier
			)

			setSealed(true)
			Mixpanel.track("Return Rental Submit Successfully", { rentalListing })
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (err: any) {
			setDisableButton(false)
			setError(err?.message || "ERROR")
			Mixpanel.track("Return Rental Submit Error", {
				error: err,
				rentalListing,
			})
			Log(err)
		}
	}

	const reset = (): void => {
		setError("")
		setTransactionID("")
	}

	const Navigation = useCallback(
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		({ nextStep, currentStep }: any): JSX.Element => (
			<div className='flex justify-center px-4 py-2 gap-[6rem]'>
				<button
					onClick={toggleModal}
					className='w-[10em] md:w-[12em]   mr-4 flex justify-center items-center border border-white rounded-md font-extrabold text-base shadow-slate-100 hover-button-white px-[1.25rem] py-2'
				>
					CLOSE
				</button>
				{currentStep === 1 && (
					<button
						className='w-[10em] md:w-[12em]  flex justify-center items-center uppercase  px-[1.25rem] py-2 bg-primary hover-button border-transparent rounded-md border-2 text-base font-extrabold text-black'
						onClick={() => {
							nextStep()
						}}
					>
						Return
					</button>
				)}
			</div>
		),
		[toggleModal]
	)

	return (
		<>
			<button
				className='w-[10em] md:w-[12em]  flex justify-center items-center uppercase  px-[1.25rem] py-2 bg-primary hover-button border-transparent rounded-md border-2 text-base font-extrabold text-black'
				disabled={disableButton}
				onClick={toggleModal}
			>
				{disableButton ? "Processing..." : "Return"}
			</button>
			<ReturnRentalModal open={show} setOpen={setShow}>
				<div className='flowty-modal loan-listing-modal'>
					<div className='flowty-modal loan-listing-modal p-2'>
						<h2 className='m-2 text-xl text-center'>RETURN RENTAL</h2>
					</div>
					<div className='p-2 border-[#545353] border-t'>
						<StepWizard
							isLazyMount
							className='flex flex-col-reverse p-3 gap-3'
							nav={<Navigation />}
						>
							<div className='flex flex-col justify-center items-center'>
								<p className='text-white text-lg'>
									Are you sure you want to return this NFT?
								</p>
							</div>

							<div className='flex flex-col justify-center items-center'>
								{error ? (
									<FailedTransaction error={error} reset={reset} />
								) : (
									!sealed && (
										<TransactionHandling
											transactionId={transactionID}
											callTransaction={execute}
										/>
									)
								)}
								<div className='flex flex-col justify-center items-center'>
									{sealed && (
										<TxSuccessful msg='Success! You have returned the item attached to this rental, your deposit has been returned' />
									)}
								</div>
							</div>
						</StepWizard>
					</div>
				</div>
			</ReturnRentalModal>
		</>
	)
}

export default ReturnRental
