import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { Flowty } from "flowty-sdk"
import React, { Fragment, useMemo } from "react"
import { useCreatorHubModal } from "../../../FlowtyCreatorHub/hooks/useCreatorHubModal"
import { TransactionProcessing } from "../../../FlowtyModal/components/common/TransactionProcessing/TransactionProcessing"
import { FormTabs } from "../../types/FlowtyCreatorHubContextTypes"
import { FlowtyCreatorHubFormValues } from "../../types/FlowtyCreatorHubTypes"
import { cleanCreatorHubCache } from "../../utils/creatorHubCacheFn"
import { CreatorHubModalNavView } from "./CreatorHubModalNavView/CreatorHubModalNavView"
import { CollectionFailed } from "./CreatorHubModalState/CollectionFailed"
import { CollectionSuccess } from "./CreatorHubModalState/CollectionSuccess"
import { ConfirmCollection } from "./CreatorHubModalState/ConfirmCollection"
import { CreatorHubSignIn } from "./CreatorHubModalState/CreatorHubSignIn"

interface CreatorHubModalProps {
	isOpen: boolean
	onCloseModal: () => void
	flowty: Flowty
	isLoggedUser: boolean
	loggedIsDapper?: boolean
	creatorHubId?: string
	logOutFn: () => void
	mixPanelFn: (event: string, data: unknown) => void
	resetForm: () => void
	values: FlowtyCreatorHubFormValues
	changeSelectedFormTab: (tab: FormTabs) => void
	uploadImageFn?: (file: File) => Promise<{ cid: string } | null>
}

export const CreatorHubModal: React.FunctionComponent<CreatorHubModalProps> = ({
	isOpen,
	onCloseModal,
	flowty,
	isLoggedUser,
	loggedIsDapper = false,
	creatorHubId,
	logOutFn,
	mixPanelFn,
	changeSelectedFormTab,
	resetForm,
	uploadImageFn,
	values,
}) => {
	const {
		collectionState,
		modalNavProps,
		transactionID,
		collectionPageTwitterURL,
	} = useCreatorHubModal({
		changeSelectedFormTab,
		creatorHubId,
		flowty,
		isLoggedUser,
		logOutFn,
		loggedIsDapper,
		mixPanelFn,
		onCloseModal,
		resetForm,
		uploadImageFn,
		values,
	})

	const { isCreating, isError, isSuccess } = collectionState
	const isMainnet = flowty.config.network === "mainnet"

	const fullCollectionPageTwitterURL = useMemo(() => {
		if (!collectionPageTwitterURL) return null
		const listingBaseURL = isMainnet ? "flowty.io" : "testnet.flowty.io"
		return `https://${listingBaseURL}${collectionPageTwitterURL}`
	}, [collectionPageTwitterURL])

	const renderTitle = useMemo(() => {
		const getTitle = (): string => {
			if (loggedIsDapper) return "Wallet Not Supported"
			if (!isLoggedUser) return "Wallet Required"
			if (!isCreating && !isError && !isSuccess)
				return "Confirm Collection Creation"
			if (isCreating) return "Creating Collection"
			if (isSuccess) return "Collection Created"
			if (isError) return "Collection Creation Failed"
			return "Create Collection"
		}
		return (
			<p className='text-lg md:text-2xl leading-6 text-white font-black'>
				{getTitle()}
			</p>
		)
	}, [isCreating, isError, isSuccess, loggedIsDapper, isLoggedUser])

	const onCloseModalHandler = (): void => {
		if (isSuccess) {
			cleanCreatorHubCache()
			resetForm()
			changeSelectedFormTab(FormTabs.WelcomeCreatorHubTab)
		}
		onCloseModal()
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-[1000]'
				onClose={onCloseModalHandler}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div
					data-testid='creator-hub-modal'
					className='fixed max-md:top-[40px] inset-0 overflow-y-auto'
				>
					<div className='flex min-h-full items-center justify-center text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full md:w-[511px] max-h-[500px] h-full flex flex-col transform rounded-lg bg-[#04070B] text-left align-middle shadow-xl transition-all border border-[#495057]'>
								<Dialog.Title
									as='div'
									className='border-b border-[#495057] px-[40px] py-[30px] md:py-8 w-full flex justify-between items-center'
								>
									{renderTitle}
									<XIcon
										onClick={onCloseModal}
										className='w-6 h-6 md:h-8 md:w-8 cursor-pointer fill-white'
									/>
								</Dialog.Title>
								<div className={`w-full py-[32px] px-[40px]`}>
									{!isLoggedUser || loggedIsDapper ? (
										<CreatorHubSignIn />
									) : (
										<>
											{!isCreating && !isSuccess && !isError && (
												<ConfirmCollection />
											)}
											{isCreating && (
												<div className='h-full flex flex-col items-center justify-center'>
													<TransactionProcessing
														transactionID={transactionID}
														isMainnet={isMainnet}
													/>
												</div>
											)}
											{isSuccess && (
												<div className='h-full flex flex-col items-center justify-center'>
													<CollectionSuccess
														isMainnet={isMainnet}
														transactionID={transactionID}
														values={values}
														collectionPageTwitterURL={
															fullCollectionPageTwitterURL
														}
														mixPanelFn={mixPanelFn}
													/>
												</div>
											)}
											{isError && (
												<div className='h-full flex flex-col items-center justify-center'>
													<CollectionFailed
														errMessage={collectionState.errorMessage}
													/>
												</div>
											)}
										</>
									)}
								</div>
								<div className='h-full w-full flex justify-center items-center border-t border-[#495057] px-[40px] py-[18px]'>
									<CreatorHubModalNavView modalNavProps={modalNavProps} />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
