import React, { useEffect } from "react"
import FlowtyLink from "../../../../FlowtyModal/components/common/FlowtyLink"
import { flowtyDiscordLink } from "flowty-common"
import { parseError } from "flowty-sdk"
import { ReactComponent as RobotOops } from "../../../../assets/robot-oops.svg"
import Swal from "sweetalert2"

interface CollectionFailedProps {
	errMessage?: string | null
}

export const CollectionFailed: React.FunctionComponent<
	CollectionFailedProps
> = ({ errMessage }) => {
	const { title, description, swalAlertOptions } = parseError(errMessage ?? "")

	useEffect(() => {
		if (!swalAlertOptions) return
		const handleSwal = async () => {
			await Swal.fire({
				background: "#031021",
				color: "#FFFFFF",
				confirmButtonColor: "#FF6969",
				icon: "error",
				showConfirmButton: true,
				text: description,
				timer: 5000,
			})
		}

		handleSwal()
	}, [swalAlertOptions])

	return (
		<div className='w-full md:w-[400px] flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			<div className='flex flex-col justify-center items-center gap-[8px]'>
				<RobotOops className='w-[140px] h-[140px]' />
				<p className='text-white text-[16px] font-semibold text-center'>
					{title}
				</p>
			</div>
			<p className='text-[#F8F9FA] text-center text-[14px] leading-normal font-[400]'>
				<div>{description}</div>
				<FlowtyLink text='Discord' href={flowtyDiscordLink} />
			</p>
		</div>
	)
}
