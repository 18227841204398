import {
	OpensearchFlowNFT,
	OpensearchRentalAvailableData,
	Order,
} from "flowty-common"
import { FlowtyModalCarousel } from "../../../../FlowtyModalCarousel"
import { ShareActiveListing } from "../../../../TwitterShare/ShareCurrentListing"
import { useFlowtyListingModalContext } from "../../../contexts/FlowtyListingModalContext/FlowtyListingModalContext"
import { SingleRentalItemView } from "../../PurchaseModalView/FundRentalView/SingleRentalItemView/SingleRentalItemView"

interface RentalDelistingProps {
	rentalOrder?: OpensearchRentalAvailableData[]
	selectedOrder: OpensearchRentalAvailableData | null
}

export const RentalDelisting: React.FunctionComponent<RentalDelistingProps> = ({
	rentalOrder,
	selectedOrder,
}) => {
	const {
		isDelist,
		openSearchFlowNFT,
		updateSelectedOrder,
		orderFromChildAccount,
		isMainnet,
		collectionDisplayName,
	} = useFlowtyListingModalContext()

	const orderAddress = rentalOrder
		? rentalOrder?.[0]?.flowtyStorefrontAddress
		: selectedOrder?.flowtyStorefrontAddress
	return (
		<div className='flex flex-col gap-3 h-full w-full md:w-[400px]'>
			{rentalOrder && rentalOrder.length > 1 ? (
				<>
					{isDelist.rent && (
						<p className='text-sm font-normal text-white'>
							Rent listing will be removed from the marketplace.
						</p>
					)}

					<FlowtyModalCarousel
						updateSelectedOrder={updateSelectedOrder}
						orders={rentalOrder}
						selectedOrder={selectedOrder as OpensearchRentalAvailableData}
					/>
				</>
			) : (
				<>
					{isDelist.rent && (
						<p className='text-sm font-normal text-white'>
							Rent listing will be removed from the marketplace.
						</p>
					)}
					<SingleRentalItemView
						order={selectedOrder as OpensearchRentalAvailableData}
					/>
				</>
			)}
			{isDelist.rent && orderFromChildAccount && (
				<div className='text-orange-500 text-sm font-normal py-2'>
					NFT was listed while connected with
					{` ${orderAddress}`} as Main. Please sign in with {` ${orderAddress}`}{" "}
					to delist.
				</div>
			)}
			{!isDelist.rent && (
				<ShareActiveListing
					nft={openSearchFlowNFT as OpensearchFlowNFT}
					order={selectedOrder as Order}
					listingType={selectedOrder?.listingKind ?? "rental"}
					isMainnet={isMainnet}
					collectionDisplayName={collectionDisplayName}
				/>
			)}
		</div>
	)
}
