/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-unused-modules */
import * as fcl from "@onflow/fcl"
import { FCLTransactionResult, getMessageFromError } from "flowty-common"
import { delay } from "../../util/delay"
import { Log } from "../../util/Log"
import { CustomErrorMessages } from "flowty-sdk/lib/utils/ContractErrors"

interface SendTxProps {
	transactionCdcScript: string | ScriptWithParams
	args: Array<any>
	txAvailableCallback?: (transactionId: string) => void
}

export interface ScriptParam {
	placeholder: string
	param: string
}

export interface ScriptWithParams {
	script: string
	params: ScriptParam[]
}

const getScriptContent = async (
	transactionCdcScript: string
): Promise<string> => {
	if (!transactionCdcScript.endsWith(".cdc")) {
		return transactionCdcScript as string
	}

	const transactionScriptRaw = await fetch(transactionCdcScript)
	return transactionScriptRaw.text()
}

const loadScriptContent = async (
	transactionCdcScript: string | ScriptWithParams
): Promise<string> => {
	let scriptContent

	if (typeof transactionCdcScript === "string") {
		scriptContent = await getScriptContent(transactionCdcScript)
	} else {
		const scriptParams = transactionCdcScript as ScriptWithParams
		let scriptContentWithParams = await getScriptContent(scriptParams.script)
		scriptParams.params.forEach(({ placeholder, param }: ScriptParam) => {
			scriptContentWithParams = scriptContentWithParams.replaceAll(
				placeholder,
				param
			)
		})

		scriptContent = scriptContentWithParams
	}
	return scriptContent
}
export const waitForSeal = async (
	transactionID: string,
	retries: number = 5,
	backoff: number = 1000
): Promise<FCLTransactionResult> => {
	try {
		return (await fcl.tx(transactionID).onceSealed()) as FCLTransactionResult
	} catch (e) {
		const msg = getMessageFromError(e)
		if (
			msg.includes("panic") ||
			msg.includes("assertion failed") ||
			msg.includes("Execution failed")
		) {
			throw new Error(msg)
		}

		if (retries === 0) {
			throw new Error(CustomErrorMessages.UnableToFollowTransactionResult)
		}
		await delay(backoff)
		return waitForSeal(transactionID, retries - 1)
	}
}

export const sendMutation = async (
	transactionCdcScript: string | ScriptWithParams,
	args: Array<any>, // we have to use any here because fcl is not typed :(
	txAvailableCallback?: (transactionId: string) => void
): Promise<FCLTransactionResult> => {
	Log("SEND Mutation with script: ", transactionCdcScript)
	const cadence = await loadScriptContent(transactionCdcScript)
	Log("sendMutation", { cadence }, ...args)

	const transactionId = await fcl.mutate({
		args: (arg: any, t: any) => args, // we have to use any here because fcl is not typed :(
		authorizations: [fcl.authz],
		cadence,
		limit: 9999,
		payer: fcl.authz,
		proposer: fcl.authz,
	})
	Log("submitted transaction with ID:", transactionId)

	if (txAvailableCallback) {
		Log("sendTx callback for submitted transaction")
		txAvailableCallback(transactionId)
	}
	const transaction = await waitForSeal(transactionId)
	Log("Transaction sealed:", { transaction })
	return transaction
}

export const sendTx = async ({
	transactionCdcScript,
	args,
	txAvailableCallback,
}: SendTxProps): Promise<any> => {
	Log("SEND TX with script: ", transactionCdcScript)
	const scriptContent = await loadScriptContent(transactionCdcScript)
	Log("sendTx", { scriptContent }, ...args)

	const response = await fcl.mutate({
		args: (_arg: any, _t: any) => args,
		cadence: scriptContent,
		limit: 9999,
	})

	if (txAvailableCallback) {
		Log("sendTx callback for submitted transaction")
		txAvailableCallback(response)
	}
	const res = await waitForSeal(response)
	Log("sendTx res", { res })
	return res
}
