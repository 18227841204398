export enum ErrorType {
	InsufficientBalance = "InsufficientBalance",
	NetworkError = "NetworkError",
	UnexpectedError = "UnexpectedError",
	NoValidReceiver = "NoValidReceiver",
	ExpiredSession = "ExpiredSession",
	InvalidListing = "InvalidListing",
	UnableToFollowTransactionResult = "UnableToFollowTransactionResult",
	PopUpBlockerEnabled = "PopUpBlockerEnabled",
	SwitchNetwork = "SwitchNetwork",
}
interface ErrorDetails {
	title: string
	description: string
	triggerStrings: string[]
	swalAlertOptions?: boolean
}

export enum CustomErrorMessages {
	UnableToFollowTransactionResult = "Uh oh! Something went wrong while trying to follow your transaction results",
}

export const errorMessages: Record<ErrorType, ErrorDetails> = {
	[ErrorType.InsufficientBalance]: {
		description:
			"Your wallet is out of storage capacity. Please add 0.1 FLOW to your account then retry.",
		title: "Insufficient FLOW Balance",
		triggerStrings: [
			"insufficient balance",
			"cannot withdraw tokens",
			"Amount withdrawn must be less",
			"self.balance >= amount",
		],
	},
	[ErrorType.ExpiredSession]: {
		// This error message is not being saved in some cases, comes from blocto, captured error only shows "user rejected the transaction"
		description:
			"Your session has expired. Please sign out and sign back in then retry the transaction.",
		title: "Expired Session",
		triggerStrings: [
			"The session id provided was incorrect",
			"Please try disconnecting and reconnecting your wallet",
		],
	},
	[ErrorType.InvalidListing]: {
		description:
			"Listing is no longer valid and has been removed from the marketplace.",
		title: "Invalid Listing",
		triggerStrings: [
			"listing is no longer available",
			"failed to borrow listing nft",
		],
	},
	[ErrorType.NoValidReceiver]: {
		description:
			"Sign in to Flowty with recipient wallet > go to NFT collection page > enable collection > retry transaction",
		title: "No Valid Receiver Found",
		triggerStrings: ["no valid receiver found"],
	},
	[ErrorType.PopUpBlockerEnabled]: {
		description:
			"Pop-up blocker is enabled. Please allow pop-ups and try again.",
		swalAlertOptions: true,
		title: "Pop-up Blocker Enabled",
		triggerStrings: ["Popup failed to open"],
	},
	[ErrorType.NetworkError]: {
		description:
			"We're having trouble connecting to the network. Please try again later.",
		title: "Network Error",
		triggerStrings: ["network error"],
	},
	[ErrorType.SwitchNetwork]: {
		description: "Please sign out and sign back in then retry the transaction.",
		title: "Not Connected to Mainnet",
		triggerStrings: [
			"is invalid for chain flow-testnet",
			"is invalid for chain flow-mainnet",
		],
	},
	[ErrorType.UnableToFollowTransactionResult]: {
		description:
			"Flowty cannot verify if the transaction was successful. We recommend one of the following actions (depending on the transaction type): refresh page, visit profile or check account balance.", // TODO add: "or visit {Flowdiver}"" Insert link to users flowdiver page
		title: "Unable to Follow Transaction",
		triggerStrings: [CustomErrorMessages.UnableToFollowTransactionResult],
	},
	[ErrorType.UnexpectedError]: {
		description: "Your transaction failed. Please try again.",
		title: "Transaction Failed",
		triggerStrings: [],
	},
}

export const parseError = (
	error: string
): { title: string; description: string; swalAlertOptions?: boolean } => {
	const detailedMessage = error.toLowerCase()

	for (const [_, value] of Object.entries(errorMessages) as [
		ErrorType,
		ErrorDetails
	][]) {
		for (const trigger of value.triggerStrings) {
			if (detailedMessage.includes(trigger.toLowerCase())) {
				return {
					description: value.description,
					swalAlertOptions: value.swalAlertOptions ?? undefined,
					title: value.title,
				}
			}
		}
	}

	return {
		description: "Your transaction failed. Please try again.",
		title: "Transaction Failed",
	}
}
