/* eslint-disable import/no-unused-modules */
import { addDays, getUnixTime } from "date-fns"
import {
	getMessageFromError,
	ListingAvailableData,
	OfferCreated,
	OpensearchFlowNFT,
	Order,
	OrdersType,
	RentalListingAvailableData,
	StorefrontV2ListingAvailable,
	SupportedTokens,
} from "flowty-common"
import { LoanRentalFilteredData, Flowty } from "flowty-sdk"
import { PurchaseState } from "../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { Dispatch, SetStateAction } from "react"

const IS_STORYBOOK = process.env.IS_STORYBOOK

interface PurchaseModalFnBuilderProps {
	listingType: string
	openSearchFlowNFT: OpensearchFlowNFT
	nftOrders?: OrdersType | null
	singleListing: Order | undefined
	selectedOrder: {
		purchase?: Order | null
		fundLoan?: Order | null
		fundRental?: Order | null
	}
	offer?: OfferCreated
	isLostAndFound?: boolean
	ticketID?: string
	lostAndFoundCatalogIdentifier?: string
	mixPanelFn: (event: string, data: unknown) => void
	nftReceiverAddress: string
	nftProviderPathIdentifier: string
	enabledAutoReturn: boolean
	ftProviderAddress: string
	offerAmount: number
	offerDuration: number
	privateFTPath: string
	txAvailableCallback: (_: string | null) => void
	token: string
	setIsLoading: Dispatch<SetStateAction<PurchaseState>>
	setError: Dispatch<SetStateAction<PurchaseState>>
	setIsSealed: Dispatch<SetStateAction<PurchaseState>>
	loanRentalActionsData?: LoanRentalFilteredData
	flowty: Flowty
}

export const purchaseModalFnBuilder = async ({
	listingType,
	openSearchFlowNFT,
	nftOrders,
	singleListing,
	selectedOrder,
	mixPanelFn,
	nftReceiverAddress,
	nftProviderPathIdentifier,
	isLostAndFound,
	ticketID,
	lostAndFoundCatalogIdentifier,
	enabledAutoReturn,
	ftProviderAddress,
	offer,
	offerAmount,
	offerDuration,
	privateFTPath,
	txAvailableCallback,
	token,
	setIsLoading,
	setError,
	setIsSealed,
	loanRentalActionsData,
	flowty,
}: PurchaseModalFnBuilderProps): Promise<undefined> => {
	if (isLostAndFound) {
		try {
			setIsLoading((prevState: PurchaseState) => ({
				...prevState,
				lostAndFound: true,
			}))
			setError((prevState: PurchaseState) => ({
				...prevState,
				lostAndFound: false,
			}))
			setIsSealed((prevState: PurchaseState) => ({
				...prevState,
				lostAndFound: false,
			}))

			if (IS_STORYBOOK) {
				txAvailableCallback("mockTxId")
				setTimeout(() => {
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						lostAndFound: false,
					}))
					if (openSearchFlowNFT.type === "ERROR_TYPE_MOCK") {
						setError((prevState: PurchaseState) => ({
							...prevState,
							lostAndFound: true,
						}))
					} else {
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							lostAndFound: true,
						}))
					}
				}, 3000)
				return
			}

			const response = await flowty.redeemLostAndFound({
				catalogIdentifier: lostAndFoundCatalogIdentifier ?? "",
				nftTypeIdentifier: openSearchFlowNFT.type,
				receiverAddress: nftReceiverAddress,
				ticketID: ticketID ?? "",
				txAvailableCallback: txAvailableCallback,
			})

			if (response?.status === 4) {
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					lostAndFound: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					lostAndFound: false,
				}))
				mixPanelFn(`Successful Lost And Found redeem transaction`, {
					listingData: openSearchFlowNFT,
					ticketID: ticketID ?? "",
					transferRecipient: nftReceiverAddress,
				})
				return
			}
			if (
				(response?.status !== 4 && response?.toString()?.includes("Error")) ||
				response?.toString()?.includes("Declined")
			) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				throw new Error(response as any)
			}
		} catch (e) {
			setError((prevState: PurchaseState) => ({
				...prevState,
				errorMessage: getMessageFromError(e),
				lostAndFound: true,
			}))
			setIsLoading((prevState: PurchaseState) => ({
				...prevState,
				lostAndFound: false,
			}))
			mixPanelFn(`Error transfer listing transaction`, {
				err: e,
				listingData: openSearchFlowNFT,
			})
		}
	}

	switch (listingType) {
		case "purchase":
			const order = singleListing
				? (singleListing as StorefrontV2ListingAvailable)
				: selectedOrder.purchase
				? (selectedOrder.purchase as StorefrontV2ListingAvailable)
				: (nftOrders?.storefront?.[0] as StorefrontV2ListingAvailable)
			try {
				if (order.nftID !== openSearchFlowNFT.id) {
					throw new Error("Order NFT is not the same as the selected NFT.")
				}
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					purchase: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					purchase: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					purchase: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							purchase: false,
						}))
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							purchase: true,
						}))
					}, 3000)
					return
				}

				const tokenMetadata = flowty.tokens.getTokenInfo(order.paymentTokenName)
				const response = await flowty.purchaseStorefrontListing({
					ftProviderAddress: ftProviderAddress,
					listing: singleListing
						? (singleListing as StorefrontV2ListingAvailable)
						: selectedOrder.purchase
						? (selectedOrder.purchase as StorefrontV2ListingAvailable)
						: (nftOrders?.storefront?.[0] as StorefrontV2ListingAvailable),
					nftReceiverAddress: nftReceiverAddress,
					privateFTPath: privateFTPath,
					token: tokenMetadata,
					txAvailableCallback,
				})

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						purchase: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						purchase: false,
					}))
					mixPanelFn(`Successful purchase storefront transaction`, {
						listingData: order,
						token: order.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					errorMessage: getMessageFromError(e),
					purchase: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					purchase: false,
				}))
				mixPanelFn(`Error purchase storefront transaction`, {
					err: e,
					listingData: order,
					token: order.paymentTokenName,
				})
			}
			break
		case "makeOffer":
			try {
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					makeOffer: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					makeOffer: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					makeOffer: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							makeOffer: false,
						}))
						if (openSearchFlowNFT.id === "ERROR_TYPE_MOCK") {
							setError((prevState: PurchaseState) => ({
								...prevState,
								makeOffer: true,
							}))
							return
						}
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							makeOffer: true,
						}))
					}, 6000)
					return
				}

				const today = new Date()
				const expiryDate = addDays(today, offerDuration)
				const expiryTimestamp = getUnixTime(expiryDate)
				const tokenType = flowty.tokens.getTokenIdentifier(
					token as SupportedTokens
				)

				const tokenData = flowty.tokens.getTokenInfo(token as SupportedTokens)
				const response = await flowty.makeOffer({
					expiry: expiryTimestamp,
					ftProviderAddress: ftProviderAddress,
					ftProviderPathIdentifier: privateFTPath,
					nftData: {
						contractAddress: openSearchFlowNFT?.contractAddress,
						contractName: openSearchFlowNFT?.contractName,
						id: openSearchFlowNFT?.id.toString(),
						nftOwner: openSearchFlowNFT?.owner,
						type: openSearchFlowNFT?.type,
					},
					nftReceiverAddress: nftReceiverAddress,
					offerAmount,
					token: tokenData,
					tokenIdentifier: tokenType,
					txAvailableCallback,
				})

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						makeOffer: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						makeOffer: false,
					}))
					mixPanelFn(`Storefront offer created successful transaction`, {
						currencyToken: token,
						expiry: expiryDate,
						listingData: openSearchFlowNFT,
						salePrice: offerAmount,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					errorMessage: getMessageFromError(e),
					makeOffer: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					makeOffer: false,
				}))
				mixPanelFn(`Storefront offer created failed transaction`, {
					currencyToken: token,
					err: e,
					listingData: openSearchFlowNFT,
					salePrice: offerAmount,
				})
			}
			break
		case "cancelOffer":
			try {
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					cancelOffer: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					cancelOffer: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					cancelOffer: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							cancelOffer: false,
						}))
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							cancelOffer: true,
						}))
					}, 6000)
					return
				}

				const response = await flowty.cancelOffer({
					offerResourceID: offer?.offerResourceID || "",
					token: token,
					txAvailableCallback,
				})

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						cancelOffer: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						cancelOffer: false,
					}))
					mixPanelFn(`Storefront offer cancel successful transaction`, {
						listingData: openSearchFlowNFT,
						offer: offer?.offerResourceID,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					cancelOffer: true,
					errorMessage: getMessageFromError(e),
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					cancelOffer: false,
				}))
				mixPanelFn(`Storefront offer cancel failed transaction`, {
					err: e,
					listingData: openSearchFlowNFT,
					offer: offer?.offerResourceID,
				})
			}
			break
		case "fundLoan":
			const fundOrder = singleListing
				? (singleListing as ListingAvailableData)
				: selectedOrder.fundLoan
				? (selectedOrder.fundLoan as ListingAvailableData)
				: (nftOrders?.loan?.[0] as ListingAvailableData)

			if (fundOrder.nftID !== openSearchFlowNFT.id) {
				throw new Error("Order NFT is not the same as the selected NFT.")
			}
			try {
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					fundLoan: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					fundLoan: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					fundLoan: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							fundLoan: false,
						}))
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							fundLoan: true,
						}))
					}, 6000)
					return
				}

				const listingData = singleListing
					? (singleListing as ListingAvailableData)
					: selectedOrder.fundLoan
					? (selectedOrder.fundLoan as ListingAvailableData)
					: (nftOrders?.loan?.[0] as ListingAvailableData)
				const tokenMetadata = flowty.tokens.getTokenInfo(
					listingData.paymentTokenName
				)

				const response = await flowty.fundLoanListing({
					ftPrivatePathIdentifier: privateFTPath,
					ftProviderAddress: ftProviderAddress,
					listingData,
					nftData: {
						contractAddress: openSearchFlowNFT.contractAddress,
						contractName: openSearchFlowNFT.contractName,
						id: openSearchFlowNFT.id.toString(),
						nftOwner: openSearchFlowNFT.owner,
						type: openSearchFlowNFT.type,
					},
					nftReceiverAddress: nftReceiverAddress,
					token: tokenMetadata,
					txAvailableCallback,
				})

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						fundLoan: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						fundLoan: false,
					}))
					mixPanelFn(`Successful fund transaction`, {
						listingData: fundOrder,
						token: fundOrder.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					errorMessage: getMessageFromError(e),
					fundLoan: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					fundLoan: false,
				}))
				mixPanelFn(`Error fund transaction`, {
					err: e,
					listingData: fundOrder,
					token: fundOrder.paymentTokenName,
				})
			}
			break
		case "fundRental":
			const fundRent = singleListing
				? (singleListing as RentalListingAvailableData)
				: selectedOrder.fundRental
				? (selectedOrder.fundRental as RentalListingAvailableData)
				: (nftOrders?.rental?.[0] as RentalListingAvailableData)

			if (fundRent.nftID !== openSearchFlowNFT.id) {
				throw new Error("Order NFT is not the same as the selected NFT.")
			}
			try {
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					fundRental: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					fundRental: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					fundRental: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							fundRental: true,
						}))
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							fundRental: false,
						}))
					}, 6000)
					return
				}

				const tokenMetadata = flowty.tokens.getTokenInfo(
					fundRent.paymentTokenName
				)
				const response = await flowty.fundRentalListing({
					enabledAutoReturn: enabledAutoReturn,
					ftPrivatePathIdentifier: privateFTPath,
					ftProviderAddress: ftProviderAddress,
					listingData: singleListing
						? (singleListing as RentalListingAvailableData)
						: selectedOrder.fundRental
						? (selectedOrder.fundRental as RentalListingAvailableData)
						: (nftOrders?.rental?.[0] as RentalListingAvailableData),
					nftData: {
						contractAddress: openSearchFlowNFT.contractAddress,
						contractName: openSearchFlowNFT.contractName,
						id: openSearchFlowNFT.id.toString(),
						nftOwner: openSearchFlowNFT.owner,
						type: openSearchFlowNFT.type,
					},
					nftProviderPathIdentifier: nftProviderPathIdentifier,
					nftReceiverAddress: nftReceiverAddress,
					token: tokenMetadata,
					txAvailableCallback,
				})

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						fundRental: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						fundRental: false,
					}))
					mixPanelFn(`Successful rent transaction`, {
						listingData: fundRent,
						token: fundRent.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response)
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					errorMessage: getMessageFromError(e),
					fundRental: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					fundRental: false,
				}))
				mixPanelFn(`Error rent transaction`, {
					err: e,
					listingData: fundRent,
					token: fundRent.paymentTokenName,
				})
			}
			break
		case "rentalBorrower":
			if (loanRentalActionsData?.nftID !== openSearchFlowNFT.id) {
				throw new Error("Order NFT is not the same as the selected NFT.")
			}
			try {
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					rentalBorrower: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					rentalBorrower: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					rentalBorrower: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							rentalBorrower: true,
						}))
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							rentalBorrower: false,
						}))
					}, 6000)
					return
				}

				const tokenMetadata = flowty.tokens.getTokenInfo(
					loanRentalActionsData?.paymentTokenName as SupportedTokens
				)
				const response = await flowty.returnRental(
					loanRentalActionsData,
					tokenMetadata,
					txAvailableCallback,
					loanRentalActionsData.renterAddress ?? "",
					nftProviderPathIdentifier
				)

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						rentalBorrower: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						rentalBorrower: false,
					}))
					mixPanelFn(`Successful rent transaction`, {
						listingData: loanRentalActionsData,
						token: loanRentalActionsData.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response.toString())
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					errorMessage: getMessageFromError(e),
					rentalBorrower: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					rentalBorrower: false,
				}))
				mixPanelFn(`Error rent transaction`, {
					err: e,
					listingData: loanRentalActionsData,
					token: loanRentalActionsData.paymentTokenName,
				})
			}
			break
		case "loanBorrower":
			if (loanRentalActionsData?.nftID !== openSearchFlowNFT.id) {
				throw new Error("Order NFT is not the same as the selected NFT.")
			}
			try {
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					loanBorrower: true,
				}))
				setError((prevState: PurchaseState) => ({
					...prevState,
					loanBorrower: false,
				}))
				setIsSealed((prevState: PurchaseState) => ({
					...prevState,
					loanBorrower: false,
				}))

				if (IS_STORYBOOK) {
					txAvailableCallback("mockTxId")
					setTimeout(() => {
						setIsSealed((prevState: PurchaseState) => ({
							...prevState,
							loanBorrower: true,
						}))
						setIsLoading((prevState: PurchaseState) => ({
							...prevState,
							loanBorrower: false,
						}))
					}, 6000)
					return
				}

				const tokenMetadata = flowty.tokens.getTokenInfo(
					loanRentalActionsData?.paymentTokenName as SupportedTokens
				)
				const response = await flowty.repayLoan({
					address: process.env.REACT_APP_ADDRESS_FLOWTY_CONTRACT || "0",
					ftPrivatePathIdentifier: privateFTPath,
					ftProviderAddress,
					fundItemID: Number(loanRentalActionsData?.fundingResourceID),
					token: tokenMetadata,
					txAvailableCallback,
				})

				if (response?.status === 4) {
					setIsSealed((prevState: PurchaseState) => ({
						...prevState,
						loanBorrower: true,
					}))
					setIsLoading((prevState: PurchaseState) => ({
						...prevState,
						loanBorrower: false,
					}))
					mixPanelFn(`Successful rent transaction`, {
						listingData: loanRentalActionsData,
						token: loanRentalActionsData.paymentTokenName,
					})
					return
				}
				if (
					(response?.status !== 4 && response?.toString()?.includes("Error")) ||
					response?.toString()?.includes("Declined")
				) {
					throw new Error(response.toString())
				}
			} catch (e) {
				setError((prevState: PurchaseState) => ({
					...prevState,
					errorMessage: getMessageFromError(e),
					loanBorrower: true,
				}))
				setIsLoading((prevState: PurchaseState) => ({
					...prevState,
					loanBorrower: false,
				}))
				mixPanelFn(`Error rent transaction`, {
					err: e,
					listingData: loanRentalActionsData,
					token: loanRentalActionsData.paymentTokenName,
				})
			}
			break
		default:
			return
	}
}
