/* eslint-disable @typescript-eslint/no-use-before-define */
import { formatAddress, TokenMetadata } from "flowty-common"
import { Config } from "../../types"

export const getStorefrontPurchaseTxn = (
	config: Config,
	isNFTCatalog: boolean,
	listingType: string,
	token: TokenMetadata
): string => {
	const listingTypeAddress = formatAddress(listingType.split(".")[1])
	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2) {
		return getFlowtyStorefrontPurchaseTxn(config, isNFTCatalog, token)
	}

	if (listingTypeAddress === config.contractAddresses.NFTStorefrontV2_Shared) {
		return getSharedStorefrontPurchaseTxn(config, isNFTCatalog, token)
	}

	throw new Error("invalid listing type")
}

const getFlowtyStorefrontPurchaseTxn = (
	config: Config,
	isNFTCatalog: boolean,
	token: TokenMetadata
): string => {
	if (token.symbol === "DUC") {
		return config.crescendo
			? flowtyStorefrontPurchaseDapperBalanceCrescendo(config)
			: flowtyStorefrontPurchaseDapperBalance(config)
	}

	if (isNFTCatalog) {
		return config.crescendo
			? flowtyStorefrontPurchaseNFTCatalogCrescendo(config)
			: flowtyStorefrontPurchaseNFTCatalog(config, token)
	}

	return config.crescendo
		? flowtyStorefrontPurchaseViewResolverCrescendo(config)
		: flowtyStorefrontPurchaseViewResolver(config, token)
}

const getSharedStorefrontPurchaseTxn = (
	config: Config,
	isNFTCatalog: boolean,
	token: TokenMetadata
): string => {
	if (token.symbol === "DUC") {
		return config.crescendo
			? sharedStorefrontPurchaseDapperBalanceCrescendo(config)
			: sharedStorefrontPurchaseDapperBalance(config)
	}

	if (isNFTCatalog) {
		return config.crescendo
			? sharedStorefrontPurchaseNFTCatalogCrescendo(config)
			: sharedStorefrontPurchaseNFTCatalog(config, token)
	}

	return config.crescendo
		? sharedStorefrontPurchaseViewResolverCrescendo(config)
		: sharedStorefrontPurchaseViewResolver(config, token)
}

const flowtyStorefrontPurchaseDapperBalance = (config: Config): string => ``

const flowtyStorefrontPurchaseNFTCatalog = (
	config: Config,
	token: TokenMetadata
): string => ``

const flowtyStorefrontPurchaseViewResolver = (
	config: Config,
	token: TokenMetadata
): string => ``

const sharedStorefrontPurchaseDapperBalance = (config: Config): string => ``

const sharedStorefrontPurchaseNFTCatalog = (
	config: Config,
	token: TokenMetadata
): string => ``

const sharedStorefrontPurchaseViewResolver = (
	config: Config,
	token: TokenMetadata
): string => ``

const sharedStorefrontPurchaseViewResolverCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import HybridCustody from ${config.contractAddresses.HybridCustody}

/// Transaction facilitates the purchase of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
transaction(
  storefrontAddress: Address,
  listingResourceID: UInt64,
  commissionRecipient: Address,
  nftReceiverAddress: Address,
  paymentAddress: Address,
  ftControllerId: UInt64
) {
    let paymentVault: @{FungibleToken.Vault}
    let collection: &{NonFungibleToken.CollectionPublic}
    let storefront: &{NFTStorefrontV2.StorefrontPublic}
    let listing: &{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(acct: auth(Storage, Capabilities) &Account) {
        if acct.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            let storefront <- NFTStorefrontV2.createStorefront()
            acct.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)

            acct.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            acct.capabilities.publish(
              acct.capabilities.storage.issue<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontStoragePath),
              at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        self.commissionRecipientCap = nil
        self.storefront = getAccount(storefrontAddress).capabilities.get<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath).borrow()
            ?? panic("Could not borrow Storefront from provided address")
        assert(self.storefront.getType() == Type<@NFTStorefrontV2.Storefront>(), message: "unexpected storefront type")

        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        let md = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if nftReceiverAddress == acct.address {
          self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)

          // unlink and relink first, if it still isn't working, then we will try to save it!
          if !self.collectionCap.check() {
            if acct.storage.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
              // pull the metdata resolver for this listing's nft and use it to configure this account's collection
              // if it is not already configured.
              let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
              acct.storage.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)
            }

            acct.capabilities.unpublish(collectionData.publicPath)
            acct.capabilities.publish(
              acct.capabilities.storage.issue<&{NonFungibleToken.Collection}>(collectionData.storagePath),
              at: collectionData.publicPath
            )
          }
        } else {
          // signer is the parent account and nftReceiver is child Account
          // get the manager resource and borrow proxyAccount
          let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
          self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
        }

        // Access the acct or child's NFT collection to store the purchased NFT.
        assert(self.collectionCap.check(), message: "Cannot borrow NFT collection receiver")
        self.collection = self.collectionCap.borrow()!

        let ftAddress = AddressUtils.parseAddress(listingDetails.salePaymentVaultType)!
        let contractName = listingDetails.salePaymentVaultType.identifier.split(separator: ".")[2]
        let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
            ?? panic("could not borrow fungible token contract")

        let ftVaultData = ftContract.resolveContractView(resourceType: listingDetails.salePaymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 

        if paymentAddress == acct.address {
          let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ftVaultData.storagePath)
            ?? panic("Cannot borrow token vault from acct storage")
          self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
        } else {
          // signer is the parent account and ftProvider is child Account
          // get the manager resource and borrow proxyAccount
          let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: paymentAddress) ?? panic("ftProvider account not found")

          let providerCap = childAcct.getCapability(controllerID: ftControllerId, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
          let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
          let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
          self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
        }

        if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if listingDetails.commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
        )
        // Deposit the NFT in the acct's collection.
        self.collection.deposit(token: <-item)
    }
}`

const flowtyStorefrontPurchaseNFTCatalogCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import AddressUtils from ${config.contractAddresses.AddressUtils}

/// Storefront purchase txn that can route nfts to a child, and take tokens from a child to pay
transaction(
    storefrontAddress: Address,
    listingResourceID: UInt64,
    commissionRecipient: Address,
    collectionIdentifier: String,
    nftReceiverAddress: Address,
    ftProviderAddress: Address,
    ftProviderControllerID: UInt64
) {
  let paymentVault: @{FungibleToken.Vault}
  let collection: &{NonFungibleToken.CollectionPublic}
  let storefront: &{NFTStorefrontV2.StorefrontPublic}
  let listing: &{NFTStorefrontV2.ListingPublic}
  var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
  var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>
  let listingAcceptor: auth(NFTStorefrontV2.Acceptor) &{NFTStorefrontV2.PrivateListingAcceptor}

  prepare(acct: auth(Storage, Capabilities) &Account) {
    if acct.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
        let storefront <- NFTStorefrontV2.createStorefront()
        acct.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)

        acct.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
        acct.capabilities.publish(
            acct.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
            at: NFTStorefrontV2.StorefrontPublicPath
        )
    }

    self.listingAcceptor = acct.storage.borrow<auth(NFTStorefrontV2.Acceptor) &{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

    let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    self.commissionRecipientCap = nil
    // Access the storefront public resource of the seller to purchase the listing.
    self.storefront = getAccount(storefrontAddress).capabilities.get<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath).borrow()
        ?? panic("Could not borrow Storefront from provided address")

    // Borrow the listing
    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
        ?? panic("Listing not found")
    let listingDetails = self.listing.getDetails()
    let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

    if nftReceiverAddress == acct.address {
        self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        // unlink and relink first, if it still isn't working, then we will try to save it!
        if !self.collectionCap.check() {
            if acct.storage.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                // if it is not already configured.
                let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                acct.storage.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            acct.capabilities.unpublish(value.collectionData.publicPath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(value.collectionData.storagePath),
                at: value.collectionData.publicPath
            )
            self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        }
    } else {
        // signer is the parent account and nftProvider is child Account
        // get the manager resource and borrow proxyAccount
        let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
        let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
        self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
        // We can't change child account links in any way
    }

    // Access the acct or child's NFT collection to store the purchased NFT.
    self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver")

    let paymentTokenAddress = AddressUtils.parseAddress(listingDetails.salePaymentVaultType)!
    let paymentTokenContractName = listingDetails.salePaymentVaultType.identifier.split(separator: ".")[2]
    let paymentTokenContract = getAccount(paymentTokenAddress).contracts.borrow<&{ViewResolver}>(name: paymentTokenContractName)
        ?? panic("payment token does not implement ViewResolver")
    let ftVaultData = paymentTokenContract.resolveContractView(resourceType: listingDetails.salePaymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData

    // Access the vault of the buyer/child to pay the sale price of the listing.
    if ftProviderAddress == acct.address{
      let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ftVaultData.storagePath)
        ?? panic("Cannot borrow token vault from acct storage")
      self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>())
        ?? panic("token provider not found for supplied child account address")
      let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
      let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
      self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
    }

    if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
      // Access the capability to receive the commission.
      let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
      assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
      self.commissionRecipientCap = _commissionRecipientCap
    } else if listingDetails.commissionAmount == 0.0 {
      self.commissionRecipientCap = nil
    } else {
      panic("Commission recipient can not be empty when commission amount is non zero")
    }
  }

  execute {
    // Purchase the NFT
    let item <- self.listing.purchase(
      payment: <-self.paymentVault,
      commissionRecipient: self.commissionRecipientCap,
      privateListingAcceptor: self.listingAcceptor
    )
    // Deposit the NFT in the buyer's collection.
    self.collection.deposit(token: <-item)
  }
}`

const flowtyStorefrontPurchaseViewResolverCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import ViewResolver from ${config.contractAddresses.ViewResolver}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import HybridCustody from ${config.contractAddresses.HybridCustody}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import AddressUtils from ${config.contractAddresses.AddressUtils}

/// Transaction facilitates the purchase of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will receive the commission.
transaction(
    storefrontAddress: Address,
    listingResourceID: UInt64,
    commissionRecipient: Address,
    nftReceiverAddress: Address,
    paymentAddress: Address,
    paymentProviderControllerID: UInt64
) {
    let paymentVault: @{FungibleToken.Vault}
    let collection: &{NonFungibleToken.CollectionPublic}
    let storefront: &{NFTStorefrontV2.StorefrontPublic}
    let listing: &{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let listingAcceptor: auth(NFTStorefrontV2.Acceptor) &{NFTStorefrontV2.PrivateListingAcceptor}
    var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(buyer: auth(Capabilities, Storage) &Account) {
        if buyer.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            buyer.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            buyer.capabilities.publish(
                buyer.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        self.listingAcceptor = buyer.storage.borrow<auth(NFTStorefrontV2.Acceptor) &{NFTStorefrontV2.PrivateListingAcceptor}>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .capabilities.get<&{NFTStorefrontV2.StorefrontPublic}>(
                NFTStorefrontV2.StorefrontPublicPath
            ).borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("No Offer with that ID in Storefront")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        let md = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>()) ?? panic("NFTCollectionData view not found on the contract.")
        let collectionData = md as! MetadataViews.NFTCollectionData

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)

            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.storage.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.storage.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)
                }

                buyer.capabilities.unpublish(collectionData.publicPath)
                buyer.capabilities.publish(
                    buyer.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(collectionData.storagePath),
                    at: collectionData.publicPath
                )
            }
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftReceiverAddress)
                ?? panic("nft receiver address is not a child account")
            self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)
            // We can't change child account links in any way
        }

        // Access the buyer or child's NFT collection to store the purchased NFT.
        assert(self.collectionCap.check(), message: "Cannot borrow NFT collection receiver")
        self.collection = self.collectionCap.borrow()!

        let paymentTokenAddress = AddressUtils.parseAddress(listingDetails.salePaymentVaultType)!
        let paymentTokenContractName = listingDetails.salePaymentVaultType.identifier.split(separator: ".")[2]
        let paymentTokenContract = getAccount(paymentTokenAddress).contracts.borrow<&{ViewResolver}>(name: paymentTokenContractName)
            ?? panic("payment token does not implement ViewResolver")
        let ftVaultData = paymentTokenContract.resolveContractView(resourceType: listingDetails.salePaymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData

        if paymentAddress == buyer.address {
            let vault = buyer.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ftVaultData.storagePath)
                ?? panic("Cannot borrow token vault from acct storage")
            self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
        } else {
          // signer is the parent account and ftProvider is child Account
          // get the manager resource and borrow proxyAccount
          let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
            ?? panic("manager does not exist")
          let childAcct = manager.borrowAccount(addr: paymentAddress) ?? panic("ftProvider account not found")

          let providerCap = childAcct.getCapability(controllerID: paymentProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>())
            ?? panic("token provider not found for supplied child account address")
          let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
          assert(ftProvider.check(), message: "invalid provider capability")
          let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
          self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
        }

        if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if listingDetails.commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
            privateListingAcceptor: self.listingAcceptor
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }
}`

const sharedStorefrontPurchaseNFTCatalogCrescendo = (
	config: Config
): string => `import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}
import AddressUtils from ${config.contractAddresses.AddressUtils}
import FungibleTokenMetadataViews from ${config.contractAddresses.FungibleTokenMetadataViews}
import HybridCustody from ${config.contractAddresses.HybridCustody}

// Shared Storefront purchase txn that can route nfts to a child, and take tokens from a child to pay
transaction(
  storefrontAddress: Address,
  listingResourceID: UInt64,
  commissionRecipient: Address,
  collectionIdentifier: String,
  nftReceiverAddress: Address,
  ftProviderAddress: Address,
  ftProviderControllerID: UInt64
) {
  let paymentVault: @{FungibleToken.Vault}
  let collection: &{NonFungibleToken.CollectionPublic}
  let storefront: &{NFTStorefrontV2.StorefrontPublic}
  let listing: &{NFTStorefrontV2.ListingPublic}
  var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
  var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

  prepare(acct: auth(Storage, Capabilities) &Account) {
    if acct.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
      let storefront <- NFTStorefrontV2.createStorefront()
      acct.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)

      acct.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
      acct.capabilities.publish(
        acct.capabilities.storage.issue<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontStoragePath),
        at: NFTStorefrontV2.StorefrontPublicPath
      )
    }

    let catalogEntry = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

    self.commissionRecipientCap = nil
    // Access the storefront public resource of the seller to purchase the listing.
    self.storefront = getAccount(storefrontAddress).capabilities.get<&{NFTStorefrontV2.StorefrontPublic}>(NFTStorefrontV2.StorefrontPublicPath).borrow()
      ?? panic("Could not borrow Storefront from provided address")
    assert(self.storefront.getType() == Type<@NFTStorefrontV2.Storefront>(), message: "unexpected storefront type")

    // Borrow the listing
    self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
      ?? panic("Listing not found")
    let listingDetails = self.listing.getDetails()

    let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

    if nftReceiverAddress == acct.address {
      self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.publicPath)

      // unlink and relink first, if it still isn't working, then we will try to save it!
      if !self.collectionCap.check() {
        if acct.storage.borrow<&AnyResource>(from: catalogEntry.collectionData.storagePath) == nil {
          // pull the metdata resolver for this listing's nft and use it to configure this account's collection
          // if it is not already configured.
          let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
          acct.storage.save(<- collectionData.createEmptyCollection(), to: catalogEntry.collectionData.storagePath)
        }

        acct.capabilities.unpublish(catalogEntry.collectionData.publicPath)
        acct.capabilities.publish(
          acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.storagePath),
          at: catalogEntry.collectionData.publicPath
        )
         self.collectionCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.publicPath)
      }
    } else {
      // signer is the parent account and nftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
      self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(catalogEntry.collectionData.publicPath)
      // We can't change child account links in any way
    }

    // Access the buyer or child's NFT collection to store the purchased NFT.
    self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver")

    // Access the vault of the buyer/child to pay the sale price of the listing.
    let ftAddress = AddressUtils.parseAddress(listingDetails.salePaymentVaultType)!
    let contractName = listingDetails.salePaymentVaultType.identifier.split(separator: ".")[2]
    let ftContract = getAccount(ftAddress).contracts.borrow<&{FungibleToken}>(name: contractName)
        ?? panic("could not borrow fungible token contract")

    let ftVaultData = ftContract.resolveContractView(resourceType: listingDetails.salePaymentVaultType, viewType: Type<FungibleTokenMetadataViews.FTVaultData>())! as! FungibleTokenMetadataViews.FTVaultData 
    if ftProviderAddress == acct.address {
      let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: ftVaultData.storagePath)
        ?? panic("Cannot borrow token vault from acct storage")
      self.paymentVault <- vault.withdraw(amount: listingDetails.salePrice)
    } else {
      // signer is the parent account and ftProvider is child Account
      // get the manager resource and borrow proxyAccount
      let manager = acct.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
        ?? panic("manager does not exist")
      let childAcct = manager.borrowAccount(addr: ftProviderAddress) ?? panic("ftProvider account not found")

      let providerCap = childAcct.getCapability(controllerID: ftProviderControllerID, type: Type<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>()) ?? panic("token provider not found for supplied child account address")
      let ftProvider = providerCap as! Capability<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>
      let ftProviderVault = ftProvider.borrow() ?? panic("child account token vault could not be borrowed")
      self.paymentVault <- ftProviderVault.withdraw(amount: listingDetails.salePrice)
    }

    if commissionRecipient != nil && listingDetails.commissionAmount != 0.0 {
      // Access the capability to receive the commission.
      let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(ftVaultData.receiverPath)
      assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have token receiving capability")
      self.commissionRecipientCap = _commissionRecipientCap
    } else if listingDetails.commissionAmount == 0.0 {
      self.commissionRecipientCap = nil
    } else {
      panic("Commission recipient can not be empty when commission amount is non zero")
    }
  }

  execute {
    // Purchase the NFT
    let item <- self.listing.purchase(
      payment: <-self.paymentVault,
      commissionRecipient: self.commissionRecipientCap
    )
    // Deposit the NFT in the buyer's (or child's) collection.
    self.collection.deposit(token: <-item)
  }
}`

const sharedStorefrontPurchaseDapperBalanceCrescendo = (
	config: Config
): string => `import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2_Shared}
import HybridCustody from ${config.contractAddresses.HybridCustody}

// Purchase an item from the shared storefront, a contract maintained by the Flow Foundation
transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address) {
    let paymentVault: @{FungibleToken.Vault}
    let collection: &{NonFungibleToken.CollectionPublic}
    let storefront: &NFTStorefrontV2.Storefront
    let listing: &{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let balanceBeforeTransfer: UFix64
    let mainPaymentVault: auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault
    var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>

    prepare(dapper: auth(BorrowValue) &Account, buyer: auth(Storage, Capabilities) &Account) {
        if buyer.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            let storefront <- NFTStorefrontV2.createStorefront()
            buyer.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            buyer.capabilities.publish(
                buyer.capabilities.storage.issue<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress).capabilities.get<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontPublicPath).borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
          ?? panic("Listing could not be found")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)

            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.storage.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.storage.save(<- collectionData.createEmptyCollection(), to: value.collectionData.storagePath)

                    buyer.capabilities.unpublish(value.collectionData.publicPath)
                    buyer.capabilities.publish(
                        buyer.capabilities.storage.issue<&{NonFungibleToken.Collection}>(value.collectionData.storagePath),
                        at: value.collectionData.publicPath
                    )
                }
                self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            }
        } else {
            let nftReceiver = getAccount(nftReceiverAddress)
            let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = nftReceiver.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }

        if !self.collectionCap.check() {
            if buyer.storage.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                // if it is not already configured.
                let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                buyer.storage.save(<-collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
            }

            buyer.capabilities.unpublish(value.collectionData.publicPath)
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver from account")

        let price = listingDetails.salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainPaymentVault = dapper.storage.borrow<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from buyer storage")
        self.balanceBeforeTransfer = self.mainPaymentVault.balance
        self.paymentVault <- self.mainPaymentVault.withdraw(amount: price)

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(commissionRecipient!).capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have DapperUtilityCoin receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }

    }

    execute {
        let listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("Listing could not be found")

        let item <- listing.purchase(payment: <-self.paymentVault, commissionRecipient: self.commissionRecipientCap)
        self.collection.deposit(token: <-item)
    }

    post {
        self.mainPaymentVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }

}`

const flowtyStorefrontPurchaseDapperBalanceCrescendo = (
	config: Config
): string => `import DapperUtilityCoin from ${config.contractAddresses.DapperUtilityCoin}
import FungibleToken from ${config.contractAddresses.FungibleToken}
import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
import MetadataViews from ${config.contractAddresses.MetadataViews}
import NFTCatalog from ${config.contractAddresses.NFTCatalog}
import NFTStorefrontV2 from ${config.contractAddresses.NFTStorefrontV2}
import HybridCustody from ${config.contractAddresses.HybridCustody}

// Purchase a listing from Flowty's NFT Storefront

transaction(storefrontAddress: Address, listingResourceID: UInt64, commissionRecipient: Address, collectionIdentifier: String, nftReceiverAddress: Address) {
    let paymentVault: @{FungibleToken.Vault}
    let collection: &{NonFungibleToken.CollectionPublic}
    var collectionCap: Capability<&{NonFungibleToken.CollectionPublic}>
    let storefront: &NFTStorefrontV2.Storefront
    let listing: &{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?
    let balanceBeforeTransfer: UFix64
    let mainPaymentVault: auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault
    let listingAcceptor: auth(NFTStorefrontV2.Acceptor) &NFTStorefrontV2.Storefront

    prepare(dapper: auth(BorrowValue) &Account, buyer: auth(Storage, Capabilities) &Account) {
        if buyer.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront()
            // save it to the account
            buyer.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront, first unlinking to ensure we remove anything that's already present
            buyer.capabilities.unpublish(NFTStorefrontV2.StorefrontPublicPath)
            buyer.capabilities.publish(
                buyer.capabilities.storage.issue<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }

        self.listingAcceptor = buyer.storage.borrow<auth(NFTStorefrontV2.Acceptor) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) ?? panic("Buyer storefront is invalid")

        let value = NFTCatalog.getCatalogEntry(collectionIdentifier: collectionIdentifier) ?? panic("Provided collection is not in the NFT Catalog.")

        self.commissionRecipientCap = nil
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress).capabilities.get<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontPublicPath)!.borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
            ?? panic("Listing not found")
        let listingDetails = self.listing.getDetails()
        let nftRef = self.listing.borrowNFT() ?? panic("nft not found")

        if nftReceiverAddress == buyer.address {
            self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // unlink and relink first, if it still isn't working, then we will try to save it!
            if !self.collectionCap.check() {
                if buyer.storage.borrow<&AnyResource>(from: value.collectionData.storagePath) == nil {
                    // pull the metdata resolver for this listing's nft and use it to configure this account's collection
                    // if it is not already configured.
                    let collectionData = nftRef.resolveView(Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
                    buyer.storage.save(<-collectionData.createEmptyCollection(), to: value.collectionData.storagePath)
                }

                buyer.capabilities.unpublish(value.collectionData.publicPath)
                buyer.capabilities.publish(
                    buyer.capabilities.storage.issue<&{NonFungibleToken.Collection}>(value.collectionData.storagePath),
                    at: value.collectionData.publicPath
                )
                self.collectionCap = buyer.capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            }
        } else {
            // signer is the parent account and nftProvider is child Account
            // get the manager resource and borrow proxyAccount
            let manager = buyer.storage.borrow<auth(HybridCustody.Manage) &HybridCustody.Manager>(from: HybridCustody.ManagerStoragePath)
                ?? panic("manager does not exist")
            let childAcct = manager.borrowAccount(addr: nftReceiverAddress) ?? panic("nft receiver address is not a child account")
            self.collectionCap = getAccount(nftReceiverAddress).capabilities.get<&{NonFungibleToken.CollectionPublic}>(value.collectionData.publicPath)
            // We can't change child account links in any way
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.collection = self.collectionCap.borrow() ?? panic("Cannot borrow NFT collection receiver from account")

        let price = listingDetails.salePrice

        // Access the vault of the buyer to pay the sale price of the listing.
        self.mainPaymentVault = dapper.storage.borrow<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DapperUtilityCoin vault from buyer storage")
        self.balanceBeforeTransfer = self.mainPaymentVault.balance
        self.paymentVault <- self.mainPaymentVault.withdraw(amount: price)

        let commissionAmount = self.listing.getDetails().commissionAmount
        if commissionRecipient != nil && commissionAmount != 0.0 {
            let _commissionRecipientCap = getAccount(commissionRecipient).capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have DapperUtilityCoin receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }

    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap,
            privateListingAcceptor: self.listingAcceptor
        )
        // Deposit the NFT in the buyer's collection.
        self.collection.deposit(token: <-item)
    }

    post {
        self.mainPaymentVault.balance == self.balanceBeforeTransfer: "DapperUtilityCoin leakage"
    }

}`
