import {
	nftTypeAndIdToLocationData,
	OpensearchFlowNFT,
	OpenSearchListingAvailableData,
	OpensearchRentalAvailableData,
	OpensearchStorefrontAvailableData,
	Order,
	SupportedTokens,
} from "flowty-common"
import React from "react"
import { ListingOrderDataType } from "../FlowtyModal/contexts/FlowtyListingModalContext/types/ListingModalContextTypes"
import { PurchaseOrderDataType } from "../FlowtyModal/contexts/FlowtyPurchaseModalContext/types/PurchaseModalContextTypes"
import {
	LoanListingOrderType,
	RentListingOrderType,
	StorefrontAcceptOfferOrderType,
} from "../Types/GlobalTypes"
import { TwitterShareButton } from "./TwitterShareButton"
import {
	formatAmount,
	getDetails,
	getDurationPeriod,
	ListingType,
} from "./utility"

function createCurrentListingTwitterHref({
	listingType,
	isMainnet,
	order,
	nft,
	values,
	isNewListing,
	collectionDisplayName,
}: {
	listingType: ListingType | "sale" | "loan" | "transfer" | "rent"
	isMainnet: boolean
	nft?: OpensearchFlowNFT
	order?: Order
	values?: ListingOrderDataType | PurchaseOrderDataType
	isNewListing?: boolean
	collectionDisplayName?: string | null
}): string {
	const locationData = nftTypeAndIdToLocationData(
		nft?.type || "",
		nft?.id || ""
	)

	const listingBaseURL = isMainnet ? "flowty.io" : "testnet.flowty.io"

	const card = nft?.card
	const assetPageLink = `${listingBaseURL}/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID} `

	const title = card?.title?.includes("#")
		? card?.title.replace("#", "%23")
		: card?.title

	const postDetails = nft
		? `%0A%0A🖼️ ${
				listingType === "loan" ? "Collateral" : "NFT"
		  }: ${title}${getDetails(card)}%0A🌎 Collection: ${
				collectionDisplayName ?? nft?.card?.collectionName
		  }`
		: ""

	switch (listingType) {
		case "sale":
		case "storefront": {
			let salePrice: number
			let token: SupportedTokens
			if (isNewListing) {
				const storefrontValues = values as StorefrontAcceptOfferOrderType
				salePrice = +storefrontValues.salePrice
				token = storefrontValues.paymentTokenName as SupportedTokens
			} else {
				const storefrontOrder = order as OpensearchStorefrontAvailableData
				salePrice = storefrontOrder?.amount
				token = storefrontOrder?.paymentTokenName
			}
			const postSaleDetails = `${postDetails}%0A💵 Sale Price: ${formatAmount(
				salePrice,
				token
			)}%0A`
			return `https://twitter.com/intent/tweet?text=Check%20out%20my%20new%20listing%20on%20@flowty_io%20${postSaleDetails}https://${assetPageLink}`
		}
		case "rental": {
			let feeAmount: number
			let depositAmount: number
			let token: SupportedTokens
			let term: number
			let duration: string
			if (isNewListing) {
				const rentalValues = values as RentListingOrderType
				feeAmount = +rentalValues.amount
				depositAmount = +rentalValues.deposit
				token = rentalValues.paymentTokenName as SupportedTokens
				term = +rentalValues.term
				duration = getDurationPeriod(term * 60 * 60 * 24)
			} else {
				const rentalOrder = order as OpensearchRentalAvailableData
				feeAmount = rentalOrder.amount
				depositAmount = rentalOrder.deposit
				token = rentalOrder.paymentTokenName
				term = rentalOrder.term
				duration = getDurationPeriod(term)
			}
			const postRentalDetails = `${postDetails}%0A💵 Rental Fee: ${formatAmount(
				feeAmount,
				token
			)}%0A💰 Refundable Deposit: ${formatAmount(
				depositAmount,
				token
			)}%0A⏲️ Duration: ${duration}%0A`

			return `https://twitter.com/intent/tweet?text=Check%20out%20my%20new%20rental%20listing%20on%20@flowty_io%20${postRentalDetails}https://${assetPageLink}`
		}

		case "loan": {
			let fundingAmount: number
			let repaymentAmount: number
			let token: SupportedTokens
			let term: number
			let duration: string
			if (isNewListing) {
				if (!values) return ""
				const loanValues = values as LoanListingOrderType
				fundingAmount =
					loanValues?.derivations.calculatedValues.marketplaceAmount
				repaymentAmount = loanValues.derivations.calculatedValues.repayment
				token = loanValues.paymentTokenName as SupportedTokens
				term = +loanValues.term
				duration = getDurationPeriod(term * 60 * 60 * 24)
			} else {
				const loanOrder = order as OpenSearchListingAvailableData
				fundingAmount =
					loanOrder.derivations?.calculatedValues?.marketplaceAmount ?? 0
				repaymentAmount =
					loanOrder.derivations?.calculatedValues?.totalRepayment ?? 0
				token = loanOrder?.paymentTokenName || SupportedTokens.FLOW
				term = Number(loanOrder.term)
				duration = getDurationPeriod(term)
			}
			const postLoanDetails = `${postDetails}%0A💰 Loan: ${formatAmount(
				fundingAmount,
				token
			)}%0A💵 Repayment: ${formatAmount(
				repaymentAmount,
				token
			)}%0A⏲️ Duration: ${duration}%0A`
			return `https://twitter.com/intent/tweet?text=Check%20out%20my%20new%20loan%20listing%20on%20@flowty_io%20${postLoanDetails}https://${assetPageLink}`
		}
		default:
			return ""
	}
}

type ShareNewListingProps = {
	isMainnet: boolean
	listingID?: string | number | null
	listingType: ListingType | "sale" | "loan" | "transfer" | "rent"
	nft?: OpensearchFlowNFT
	values?: ListingOrderDataType | PurchaseOrderDataType
	collectionDisplayName?: string | null
}

export const ShareNewListing: React.FC<ShareNewListingProps> = ({
	isMainnet,
	listingID,
	listingType,
	nft,
	values,
	collectionDisplayName,
}) => {
	if (!nft) {
		return null
	}
	const href = createCurrentListingTwitterHref({
		collectionDisplayName,
		isMainnet,
		isNewListing: true,
		listingType,
		nft,
		values,
	})

	return listingID !== null ? <TwitterShareButton href={href} /> : null
}

type ShareActiveListingProps = {
	nft: OpensearchFlowNFT
	listingType: ListingType
	order: Order
	isMainnet: boolean
	collectionDisplayName?: string | null
}

export const ShareActiveListing: React.FC<ShareActiveListingProps> = ({
	listingType,
	order,
	collectionDisplayName,
	nft,
	isMainnet,
}) => {
	const href = createCurrentListingTwitterHref({
		collectionDisplayName,
		isMainnet,
		listingType,
		nft,
		order,
	})

	return <TwitterShareButton href={href} />
}
